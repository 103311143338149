import dayjs from "dayjs";
import { Button, Divider, Form, message, Modal, Tag, Typography } from "antd";
import { CheckSquareOutlined, CloseSquareOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";
import React from "react";
import { CurrencyElement } from "./ExposureMonitorDetail";
import TypographyCurrency from "../../../components/CurrencySelect/TypographyCurrency";


const prepareDefaultColumns = (getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, history, setComponentState) => {
  return [
    {
      key: 'date',
      title: 'Payment Scheduled Date',
      dataIndex: 'date',
      sorter: (a, b) => {
        const dateA = new Date(a.date.split('/').reverse().join('/'));
        const dateB = new Date(b.date.split('/').reverse().join('/'));
        return dateA - dateB;
      },
      className: 'ant-table-column-sort-new',
      defaultSortOrder: 'ascend', ...getColumnSearchProps({
        dataIndex: 'date',
        filterInputType: 'DATE',
        render: (text, record) => dayjs(text).format('DD/MM/YYYY')
      })
    },
    {
      key: 'bid',
      title: 'Payment To',
      dataIndex: 'bid',
      render: (text, record) => {
        const ben = componentState.beneficiaryList.find(i => i.id === text);
        if (ben != null) {
          return ben.nickname == null ? ben.ben_legal_name : ben.nickname
        }
        return '(pay to currency balance)'
      }
    },
    {
      key: 'amount',
      title: 'Payment Amount',
      dataIndex: 'amount',
      render: (text, record) => {
        const currency = app_state.currency_list.find(i => i.id === record.currencyID);
        if (currency && text !== null) {
          return <CurrencyElement
            currency={currency.iso_alpha_3}
            value={text}
            classes={classes}
          />
        } else {
          return '-'
        }
      }
    },
    {
      key: 'type',
      title: '',
      dataIndex: 'type',
      render: (text, record) => {
        if (text === 'PAYOUTS') {
          return <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <div style={{display: 'flex', justifyContent: 'start', gap: 5}}>
              {onEditBtnClick ? <Button type={'primary'} size="small" icon={<EditOutlined />}
                                        onClick={() => onEditBtnClick(record.id)}></Button> : <></>}
              {
                onEditBtnClick && <Button type={'primary'} size="small" icon={<DeleteOutlined />} danger={true}
                                          onClick={() => {
                                            Modal.confirm({
                                              title: `Delete ?`,
                                              content: `Are you sure, you want to delete this payout record #${record.id} ?`,
                                              onOk: () => {
                                                const loading = message.loading("Removing. Please wait..", 0);
                                                API.post("commons", "/update", {
                                                  body: {
                                                    context: record.identifier,
                                                    data: {deleted: true},
                                                    condition: {id: record.id}
                                                  }
                                                }).then(() => {
                                                  initData();
                                                  message.success("Removed");
                                                }).catch(err => {
                                                  console.log(err);
                                                  message.success("Failed");
                                                }).finally(() => loading())
                                              }
                                            })
                                          }}></Button>
              }
              {/*<Button type={'info'} size="small" onClick={() => {*/}
              {/*    const from_currency = 'AUD';*/}
              {/*    const to_currency = app_state.currency_list.find(i => i.id === record.currencyID).iso_alpha_3;*/}
              {/*    const bid = record.bid || '-'; // just to ${bid} where to put it below.*/}
              {/*    history.push(`/shortcut_transfer/${from_currency}-${to_currency}--${record.amount}-----`);*/}
              {/*}}>Add Cover (Spot)</Button>*/}
              {
                componentState.dataState === 'ALL' || componentState.dataState === 'ACTUAL'
                  ? <>
                    {
                      sendForward && <Button type={'primary'} size="small" onClick={() => {
                        const values = record;
                        values.payment_due_date = record.date;
                        values.from_currency = 1;
                        values.to_currency = record.currencyID;
                        values.description = 'Via payout';
                        values.beneficiary_id = record.bid;
                        sendForward(values);
                      }}>Add Cover</Button>
                    }
                  </>
                  : <>
                    <Button type={'primary'} size="small" onClick={() => {
                      const values = record;
                      values.settlementDate = record.date;
                      values.currencyFromID = 1;
                      values.currencyToID = record.currencyID;
                      values.amountTo = record.amount;

                      setComponentState(prev => ({...prev, isDraftForwardModelOpen: true}));
                      componentState.populateDraftForwardForm(values, app_state);
                    }}>Add Cover</Button>
                  </>
              }
            </div>
          </div>
        } else {
          if (componentState.dataState === 'ALL' || componentState.dataState === 'ACTUAL') {
            if (text === 'FORWARDS') {
              return <Button type={'info'} size={'small'}
                             onClick={() => history.push(`/forwards/detail/${record.id}`)}>View Forward</Button>
            } else {
              return <Button type={'info'} size={'small'}
                             onClick={() => history.push(`/transfers/edit/${record.id}`)}>View Transfer</Button>
            }
          }
        }
      }
    }
  ];
}
export const ExposureMonitorUtils = {
  buildPayoutColumns: (getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, onViewDocumentsBtnClick, history, setComponentState) => {
    let columns = prepareDefaultColumns(getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, history, setComponentState);
    if (onEditBtnClick == null) {
      columns = columns.filter(i => i.dataIndex !== 'type');
    }
    const column = {
      key: 'attachments',
      title: 'Attachments',
      dataIndex: 'attachments',
      render: (text, record) => {
        if (record.attachments && record.attachments.length > 0) {
          return <Tag style={{cursor: 'pointer'}} onClick={() => onViewDocumentsBtnClick(record.attachments)}
                      icon={<CheckSquareOutlined />} color={'success'}></Tag>
        } else {
          return <Tag style={{cursor: 'pointer'}} icon={<CloseSquareOutlined />} color={'warning'}></Tag>
        }
      }
    }
    columns.push(column);
    return columns;
  },
  buildCoveredColumns: (getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, onViewDocumentsBtnClick, history, setComponentState) => {

    const columns = prepareDefaultColumns(getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, history, setComponentState);

    const draftColumn = {
      key: 'draft',
      title: 'Actual/Forecast',
      dataIndex: 'draft',
      render: (text, record) => {
        return <>{record.draft ? 'Forecast Coverage' : 'Actual Cover'}</>
      }
    }

    const currencyFromCol = {
      key: 'currencyFromId',
      title: 'Currency From',
      dataIndex: 'currencyFromId',
      render: (text, record) => {
        const currency = app_state.currency_list.find(i => i.id === text);
        if (currency) {
          return <TypographyCurrency
            iso_alpha_3={currency.iso_alpha_3}>{currency.iso_alpha_3}</TypographyCurrency>
        }
        return <></>
      }
    };
    const currencyToCol = {
      key: 'currencyID',
      title: 'Currency To',
      dataIndex: 'currencyID',
      render: (text, record) => {
        const currency = app_state.currency_list.find(i => i.id === text);
        if (currency) {
          return <TypographyCurrency
            iso_alpha_3={currency.iso_alpha_3}>{currency.iso_alpha_3}</TypographyCurrency>
        }
        return <></>
      }
    };

    const rateColumn = {
      key: 'rate',
      title: 'Rate',
      dataIndex: 'rate',
      render: (text, record) => {
        return <div style={{display: 'flex', justifyContent: 'center'}}>
          <Typography.Text>
            {text != null ? text.toFixed(4) : '-'}
            <br />
            {
              componentState.dataState !== 'ACTUAL' &&
              (componentState.pageType != null && componentState.pageType !== 'SUMMARY')
                ? <Typography.Link onClick={() => {
                  componentState.onRateChangeBtnClick({row: record, val: text, isDraft: true}, app_state)
                }}>(Change Rate)</Typography.Link>
                : <></>
            }
          </Typography.Text>
        </div>
      }
    };

    const amountFrom = {
      key: 'amount_from',
      title: 'Amount From',
      dataIndex: 'amount_from',
      render: (text, record) => {
        const currency = app_state.currency_list.find(i => i.id === record.currencyFromId);
        if (currency && text !== null) {
          return <CurrencyElement
            currency={currency.iso_alpha_3}
            value={text}
            classes={classes}
          />
        } else {
          return '-'
        }
      }
    }
    columns.push(draftColumn, currencyFromCol, rateColumn, currencyToCol);
    return columns;
  },

  prepareDescriptions: (data, state, renderValue, haBalance = null, componentState = null, app_state = null, renderAverageRate = null) => {
    const descriptions = [];

    const payoutActual = data.filter(item => item.type === 'PAYOUTS' & !item.draft).reduce((acc, item) => acc + item.amount, 0);
    let forwardActual = data.filter(item => item.type !== 'PAYOUTS' && !item.draft).reduce((acc, item) => acc + item.amount, 0);
    const coveredActual = forwardActual + (haBalance ? haBalance : 0);
    let uncoveredActual = payoutActual - coveredActual
    const totalActual = uncoveredActual + coveredActual;

    const payoutDraft = data.filter(item => item.type === 'PAYOUTS' && item.draft).reduce((acc, item) => acc + item.amount, 0);
    let forwardDraft = data.filter(item => item.type !== 'PAYOUTS' && item.draft).reduce((acc, item) => acc + item.amount, 0);
    const coveredDraft = forwardDraft + (haBalance ? haBalance : 0);
    const uncoveredDraft = payoutDraft - coveredDraft;
    const totalDraft = uncoveredDraft + coveredDraft;

    const payoutActualDraft = data.filter(item => item.type === 'PAYOUTS').reduce((acc, item) => acc + item.amount, 0);
    let forwardActualDraft = data.filter(item => item.type !== 'PAYOUTS').reduce((acc, item) => acc + item.amount, 0);
    const coveredActualDraft = forwardActualDraft + (haBalance ? haBalance : 0);
    let uncoveredActualDraft = payoutActualDraft - coveredActualDraft
    const totalActualDraft = uncoveredActualDraft + coveredActualDraft;


    const actual = [
      {
        label: 'Inv. Payments',
        value: renderValue ? renderValue(totalActual) : totalActual,
        key: 'ACTUAL',
        template: ExposureMonitorUtils.renderViewDetails()
      },
      // {label: 'Forwards', value: renderValue ?  renderValue(forwardActual) : forwardActual, key: 'ACTUAL'},
      // {label: 'USD Balance', value: renderValue ?  renderValue(haBalance) : haBalance, key: 'ACTUAL'},
      {
        label: 'Covered',
        value: renderValue ? renderValue(coveredActual) : coveredActual,
        key: 'ACTUAL',
        template: ExposureMonitorUtils.renderCoveredDetail(coveredActual, totalActual, forwardActual, haBalance, app_state, componentState, renderAverageRate)
      },
      {
        label: 'Uncovered',
        value: renderValue ? renderValue(uncoveredActual) : uncoveredActual,
        key: 'ACTUAL',
        template: ExposureMonitorUtils.renderAddCover(coveredActual, uncoveredActual, totalActual, componentState, app_state)
      },
    ];

    const draft = [
      {
        label: 'Inv. Payments',
        value: renderValue ? renderValue(totalDraft) : totalDraft,
        key: 'DRAFT',
        template: ExposureMonitorUtils.renderViewDetails()
      },
      // {label: 'Forwards', value: renderValue ?  renderValue(forwardDraft) : forwardDraft, key: 'DRAFT'},
      // {label: 'USD Balance', value: renderValue ?  renderValue(haBalance)  : haBalance, key: 'DRAFT'},
      {
        label: 'Covered',
        value: renderValue ? renderValue(coveredDraft) : coveredDraft,
        key: 'DRAFT',
        template: ExposureMonitorUtils.renderCoveredDetail(coveredDraft, totalDraft, forwardDraft, haBalance, app_state, componentState, renderAverageRate)
      },
      {label: 'Uncovered', value: renderValue ? renderValue(uncoveredDraft) : uncoveredDraft, key: 'DRAFT'},
    ];

    const all = [
      {
        label: 'Inv. Payments',
        value: renderValue ? renderValue(totalActualDraft) : totalActualDraft,
        key: 'ALL',
        template: ExposureMonitorUtils.renderViewDetails()
      },
      // {label: 'Forwards', value: renderValue ?  renderValue(forwardActualDraft) : forwardActualDraft, key: 'ALL'},
      // {label: 'USD Balance', value: renderValue ?  renderValue(haBalance) : haBalance, key: 'ALL'},
      {
        label: 'Covered', value: renderValue ? renderValue(coveredActualDraft) : coveredActualDraft, key: 'ALL',
        // template: ExposureMonitorUtils.renderCoveredDetail(coveredActualDraft, totalActualDraft, forwardActualDraft, haBalance, app_state, componentState)
      },
      {label: 'Uncovered', value: renderValue ? renderValue(uncoveredActualDraft) : uncoveredActualDraft, key: 'ALL'},
    ];

    const filterByState = (items, state) => {
      return items.filter(item => item.key === state || state === 'ALL');
    };

    descriptions.push(
      ...filterByState(actual, state),
      ...filterByState(draft, state),
      ...filterByState(all, state)
    );
    return descriptions
  },
  renderViewDetails: () => {
    return <Typography.Link href={'#coverTable'}>View Details</Typography.Link>
  },
  renderCoveredDetail: (coveredActual, totalActual, forwards, haBalance, app_state = null, componentState = null, renderAverageRate = null) => {
    const val = ((coveredActual / totalActual) * 100);
    return <>
      <Typography>({isNaN(val) ? '-' : val.toFixed(0)}%)</Typography>
      {/*<Divider />*/}
      {/*<Typography.Text>The covered amount is calculated as the sum of forwards and any ledger balance.</Typography.Text>*/}
      {/*<br/><br/>*/}
      <Typography.Text>Forwards: {ExposureMonitorUtils.renderDecimal(forwards)}</Typography.Text>
      <br />
      <Typography.Text>Ledger Balance: {ExposureMonitorUtils.renderDecimal(haBalance)}</Typography.Text>
      {/*<Typography.Link onClick={() => {*/}
      {/*    Modal.info({*/}
      {/*        title: 'Cover Details',*/}
      {/*        content: <>*/}
      {/*            <Divider />*/}
      {/*            <Typography.Text>The covered amount is calculated as the sum of forwards and any ledger balance.</Typography.Text>*/}
      {/*            <br/><br/>*/}
      {/*            <Typography.Text>Forwards: {ExposureMonitorUtils.renderDecimal(forwards)}</Typography.Text>*/}
      {/*            <br/>*/}
      {/*            <Typography.Text>Ledger Balance: {ExposureMonitorUtils.renderDecimal(haBalance)}</Typography.Text>*/}
      {/*        </>,*/}
      {/*        cancelButtonProps: {hidden: true }*/}
      {/*    })*/}
      {/*}}>View Cover Detail</Typography.Link>*/}
    </>
  },
  renderAddCover: (coveredActual, uncoveredActual, totalActual, componentState, app_state) => {
    const desiredCoverage = app_state && app_state.current_client ? (app_state.current_client.exposure_desiredcoverage / 100) : 0;
    const total = (desiredCoverage * totalActual) - coveredActual;
    console.log('coveredActual', coveredActual)
    console.log('uncoveredActual', uncoveredActual)
    console.log('totalActual', totalActual)
    console.log('componentState', componentState)
    if (uncoveredActual <= 0) {
      return <></>
      // <span style={{display: 'flex', justifyContent: 'center'}}>
      //   {/*<Typography.Link onClick={() => componentState ? componentState.onAddCoverFromPieChart((uncoveredActual / totalActual), total, app_state) : null}>Add Cover Now</Typography.Link>*/}
      // </span>
    }
    return <>
      <Typography>({((uncoveredActual / totalActual) * 100).toFixed(0)}%)</Typography>
      {/*<Typography.Link onClick={() => componentState ? componentState.onAddCoverFromPieChart((uncoveredActual / totalActual), total, app_state) : null}>Add Cover Now</Typography.Link>*/}
    </>
  },
  prepareDonutPayments: (donutData, ledgerBalance, dataState = null) => {
    const data = [];

    let payments = 0;
    let forwards = 0;

    let payoutsCount = 0;
    let forwardsCount = 0;

    if (dataState === 'DRAFT') {
      payments = donutData.filter(i => i.type === 'PAYOUTS' && i.draft).map(item => item.amount).reduce((acc, num) => acc + num, 0);
      forwards = donutData.filter(i => i.type === 'FORWARDS' && i.draft).map(item => item.amount).reduce((acc, num) => acc + num, 0);
      payoutsCount = donutData.filter(i => i.type === 'PAYOUTS' && i.draft).length;
      forwardsCount = donutData.filter(i => i.type === 'PAYOUTS' && i.draft).length;
    } else if (dataState === 'ACTUAL') {
      payments = donutData.filter(i => i.type === 'PAYOUTS' && !i.draft).map(item => item.amount).reduce((acc, num) => acc + num, 0);
      forwards = donutData.filter(i => i.type === 'FORWARDS' && !i.draft).map(item => item.amount).reduce((acc, num) => acc + num, 0);
      payoutsCount = donutData.filter(i => i.type === 'PAYOUTS' && !i.draft).length;
      forwardsCount = donutData.filter(i => i.type === 'FORWARDS' && !i.draft).length;
    } else {
      payments = donutData.filter(i => i.type === 'PAYOUTS').map(item => item.amount).reduce((acc, num) => acc + num, 0);
      forwards = donutData.filter(i => i.type === 'FORWARDS').map(item => item.amount).reduce((acc, num) => acc + num, 0);
      payoutsCount = donutData.filter(i => i.type === 'PAYOUTS').length;
      forwardsCount = donutData.filter(i => i.type === 'FORWARDS').length;
    }

    const value_cover = forwards + ledgerBalance;
    const value_payments = payments;

    let valForDonut1 = (forwards + ledgerBalance) / (payments) * 100
    let valForDonut2 = 100 - valForDonut1;

    if ((forwards + ledgerBalance) > payments) {
      valForDonut1 = 100;
      valForDonut2 = 0
    }
    if (value_cover === 0 && value_payments === 0) {
      valForDonut1 = 100;
      valForDonut2 = 0
    }

    data.push({label: 'Cover', value: value_cover, percentage: valForDonut1, count: forwardsCount});
    data.push({label: 'Payments', value: value_payments, percentage: valForDonut2, count: payoutsCount});

    return data;
  },

  renderAnyCurrency: (currency, value) => {
    return new Intl.NumberFormat('en-GB', {
      currency: currency ? currency : "",
      style: 'currency',
      useGrouping: true,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }).format(value);
  },

  renderDecimal: (value) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'decimal',
      useGrouping: true,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }).format(value);
  },
  getDateRange: (dataList) => {
    const day30 = dataList.filter(i =>
      dayjs(i.date).isAfter(dayjs()) && dayjs(i.date).isBefore(dayjs().add(30, 'days'))
    );

    const day30to60 = dataList.filter(i =>
      dayjs(i.date).isAfter(dayjs().add(30, 'days')) && dayjs(i.date).isBefore(dayjs().add(60, 'days'))
    );

    const day60to90 = dataList.filter(i =>
      dayjs(i.date).isAfter(dayjs().add(60, 'days')) && dayjs(i.date).isBefore(dayjs().add(90, 'days'))
    );

    const day90plus = dataList.filter(i =>
      dayjs(i.date).isAfter(dayjs().add(90, 'days'))
    );

    return {day30, day30to60, day60to90, day90plus};
  }


}